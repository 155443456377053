<template>
  <div class="home">
  	<div class="home__top">
    	<img alt="Cactus Plant Studio" src="../assets/logo.png" data-aos="fade" data-aos-once="true">
      <div data-aos="fade" data-aos-once="true" class="text-center">
    	<p>single-needle // fine line</p>
    	<p>private studio // by appointment only</p>
    	<p>monterey, ca</p>
      </div>
      <a href="https://www.instagram.com/cactus.zack/" target="_blank">
      	<button class="btn btn__flat btn__large" data-aos="fade" data-aos-once="true"  data-aos-delay="200">
      		<i class="fab fa-instagram fa-2x mr-2"></i> cactus.zack
      	</button>
      </a>
    </div>
    <!-- <div class="text-center">
      <img alt="Cactus Plant Studio" src="../assets/fav.png" class="cactus" data-aos="fade" data-aos-once="true"  data-aos-delay="200">
    </div> -->
    <div class="home__insta hiddenSmAndDown" data-aos="fade" data-aos-once="true" data-aos-delay="200">
	    <insta-vue tag="cactus.zack"
	       :quantity="12" 
	       :cols="3"
	       :descriptions="false">
	    </insta-vue>
    </div>
    <div class="home__insta hiddenMdAndUp" data-aos="fade" data-aos-once="true" data-aos-delay="200">
      <insta-vue tag="cactus.zack"
         :quantity="12" 
         :cols="2"
         :descriptions="false">
      </insta-vue>
    </div>
    <div class="text-center mb-5">
      <img alt="Cactus Plant Studio" src="../assets/fav.png" class="cactus" data-aos="fade" data-aos-once="true" data-aos-delay="200">
    </div>
    <div class="background">
      <div class="container pb-6" data-aos="fade">
        <ContactForm />
      </div>
    </div>
    <Footer :user="currentUser" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Footer from '@/components/Footer.vue'
import ContactForm from '@/components/ContactForm.vue'
import InstaVue from "insta-vue";

export default {
  name: 'Home',
  computed: {
    ...mapState(['currentUser']),
  },
  components: {
    ContactForm,
    Loader,
    Footer,
    InstaVue
  }
}
</script>
