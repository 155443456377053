<template>
	<div class="form">
		<div class="form__contact">
			<div class="form__contact--col1" data-aos="fade" data-aos-once="true" data-aos-delay="200">
				<h2>Request an Appointment</h2>
				<p>Share your design ideas, placement, references, and size. I will then interpret this into a design created just for you.</p>
				<p>I will quote a rough estimate for your tattoo with this information. Once again, this is a rough estimate because the size and details sometimes change before the tattoo is started.</p>
				<p>Each submission is highly-valued and appreciated. However, not all designs are accepted if they don't fall in line with the value of my work.</p>
				<p>Please note:
					<ul>
						<li>Appointments are confirmed after the deposit is received</li>
						<li>Deposits are final, non-refundable, and non-transferable</li>
						<li>Due to the high-volume of inquiries, responses may take longer than a week</li>
					</ul>
				</p>
				<!-- <p>A deposit is required to book any appointment.</p>
				<p>Once confirmed, you will then receive a copy of your design the night before your appointment. I will allow some design changes, but as artists we have to be comfortable with our work in order for it to be the best possible.</p>
				<p>Built into your final cost is one complimentary touch, aftercare bandages (saniderm) & aftercare cream.</p> -->
			</div>
			<div class="form__contact--col2" data-aos="fade" data-aos-once="true" data-aos-delay="200">
				<transition name="fade">
					<div v-if="!showForm">
						<div class="lead__form pt-5 pb-5">
							<h2>Thank you!</h2>
							<div class="text-left mb-5">
					      <img alt="Cactus Plant Studio" src="../assets/fav.png" class="cactus">
					    </div>
							<p>We will be in touch shortly.</p>
							<p>Keep your eyes out from an email from us. Be sure to check your spam folder.</p>
						</div>
					</div>
				</transition>
				<transition name="fade">
				<div v-if="showForm" style="width:100%;">
					<form @submit.prevent>
						<div>
			        <label for="name">Your Name</label>
			        <input type="text" placeholder="First and Last Name" v-model.trim="contactForm.name" id="name" required />
			      </div>
			      <div>
			        <label for="email">Your Email</label>
			        <input type="text" placeholder="you@email.com" v-model.trim="contactForm.email" id="email" required/>
			      </div>
			      <div>
			        <label for="phone">Your Phone</label>
			        <input type="text" placeholder="(###)-###-####" v-mask="'(###)-###-####'" v-model.trim="contactForm.phone" id="phone" required/>
			      </div>
			      <div>
			        <label for="dob">Date of Birth</label>
			        <input type="date" v-model.trim="contactForm.dob" id="dob" required/>
			      </div>
			      <div>
			      	<label for="first">Is this your first tattoo?</label>
			      	<select v-model="contactForm.first" id="first" required class="select-css">
							  <option v-for="option in choices">
							    {{ option }}
							  </option>
							</select>
			      </div>
			      <div>
			        <label for="description">Tattoo Description</label>
			        <textarea name="qty" id="description" cols="30" rows="4" v-model.trim="contactForm.description"></textarea>
			      </div>
			      <div>
			        <label for="size">Tattoo size and placement</label>
			        <input type="text" v-model.trim="contactForm.size" placeholder="For example, 2x2 on forearm..." id="size" required/>
			      </div>
			      <div>
			        <label for="how">How did you hear about us?</label>
			        <input type="text" v-model.trim="contactForm.how" id="how" required/>
			      </div>
			      <div class="mt-5">
			      	<label>Upload Reference Image</label>
			      	<croppa 
                v-model="contactForm.image"
                :prevent-white-space="false"
                :width="280"
                :height="280"
                :file-size-limit="2048000"
                accept=".jpeg,.png,.jpg"
                :quality="6"
                :placeholder="'Upload Image'"
                canvas-color="#ffffff"
                initial-size="contain"
                @file-size-exceed="onFileSizeExceed"
                @file-type-mismatch="onFileTypeMismatch"
              ></croppa>
              <p class="caption">jpg or png file. 2MB max.</p>
			      </div>
			      <button class="btn btn__primary btn__large mt-5 mb-5" @click="submit()">
			        Submit
			        <transition name="fade">
			          <span class="ml-2" v-if="performingRequest">
			          <i class="fa fa-spinner fa-spin"></i>
			          </span>
			        </transition>
			      </button>
			    </form>
				</div>
			</transition>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
  	choices: ['Yes', 'No'],
    performingRequest: false,
    showForm: true,
    contactForm: {
    	name: '',
      email: '',
      phone: '',
      how: '',
      dob: '',
      description: '',
      first: 'Yes',
      size: '',
      image: null
    }
  }),
  methods: {
  	onFileTypeMismatch(file) {
  		alert('Invalid file type. Please choose a jpeg or png file.')
  	},
  	onFileSizeExceed(file) {
			alert('Please choose a file smaller than 2MB')
  	},
    submit() {
      this.performingRequest = true
      let contact = this.contactForm;
      console.log(contact)
      setTimeout(() => {
      	this.$store.dispatch('newLead', contact)
      	// this.contactForm.name = '',
	      // this.contactForm.email = '',
	      // this.contactForm.phone = '',
	      // this.contactForm.dob = '',
	      // this.contactForm.how = '',
	      // this.contactForm.size = '',
	      // this.contactForm.image = '',
	      // this.contactForm.description = '',
	      // this.contactForm.first = 'Yes',
	      this.performingRequest = false,
        this.showForm = false
      }, 2000)
	  }
	},
	beforeDestroy() {
  	this.contactForm.name = '',
    this.contactForm.email = '',
    this.contactForm.phone = '',
    this.contactForm.how = '',
    this.contactForm.dob = '',
    this.contactForm.image = '',
    this.contactForm.description = '',
    this.contactForm.size = '',
    this.contactForm.first = 'Yes'
  }
}
</script>

